const CellRange = require('../../model/CellRange');
const { getCombinedCellPropertiesAt, getColumnPropertiesAt, getRowPropertiesAt } = require('../../model/utils');
const SetPropertiesCommand = require('../server/SetPropertiesCommand');
const { getCellReference, getRangeIndex } = require('./cellrange');

const getRowSectionIndex = (range) => {
	const { maxColumns } = range.sheet;
	const tmprange = new CellRange(range.sheet, 0, 0, 0, 0);
	return (index) => getRangeIndex(tmprange.set(1, index, maxColumns, index));
};
const getColumnSectionIndex = (range) => {
	const { maxRows } = range.sheet;
	const tmprange = new CellRange(range.sheet, 0, 0, 0, 0);
	return (index) => getRangeIndex(tmprange.set(index, 1, index, maxRows));
};
const mapRowIndex = (fromRange, toRange) => {
	const height = fromRange.height;
	const startY = fromRange.getY1();
	const targetStartY = toRange.getY1();
	return (index) => startY + ((targetStartY + index) % height);
};
const mapColumnIndex = (fromRange, toRange) => {
	const width = fromRange.width;
	const startX = fromRange.getX1();
	const targetStartX = toRange.getX1();
	return (index) => startX + ((targetStartX + index) % width);
};
const collectRowProperties = (range, target) => {
	const allProps = [];
	const toRowIndex = mapRowIndex(range, target);
	const getSectionIndex = getRowSectionIndex(target);
	target.enumerateRows((index) => {
		allProps.push({
			ref: { row: getSectionIndex(index) },
			properties: getRowPropertiesAt(toRowIndex(index), range.sheet.rows)
		});
	});
	return allProps;
};
const collectColumnProperties = (range, target) => {
	const allProps = [];
	const toColIndex = mapColumnIndex(range, target);
	const getSectionIndex = getColumnSectionIndex(target);
	target.enumerateColumns((index) => {
		allProps.push({
			ref: { col: getSectionIndex(index) },
			properties: getColumnPropertiesAt(toColIndex(index), range.sheet.columns)
		});
	});
	return allProps;
};
const getSectionProperties = (range, target) => {
	if (range.isColumnRange() && target.isColumnRange()) return { cols: collectColumnProperties(range, target) };
	return range.isRowRange() && target.isRowRange() ? { rows: collectRowProperties(range, target) } : undefined;
};

const mapCellIndex = (range, target) => {
	const toRowIndex = mapRowIndex(range, target);
	const toColIndex = mapColumnIndex(range, target);
	const tmprange = new CellRange(range.sheet, 0, 0, 0, 0);
	return (pos) => getCellReference(tmprange.set(toColIndex(pos.x), toRowIndex(pos.y)));
};
const collectCellProperties = (range, target) => {
	const allProps = [];
	const sheet = range.sheet;
	const toCellIndex = mapCellIndex(range, target);
	const tmprange = new CellRange(target.sheet, 0, 0, 0, 0);
	target.enumerateCells(false, (pos) => {
		const idx = toCellIndex(pos);
		const ref = getCellReference(tmprange.set(pos.x, pos.y));
		allProps.push({ ref, properties: getCombinedCellPropertiesAt(idx, sheet, sheet.cells) });
	});
	return allProps;
};
const getCellProperties = (range, target) => {
	return { cells: collectCellProperties(range, target) };
};

const createPasteFormatsCommand = (range, target) => {
	const sectionProps = getSectionProperties(range, target);
	if (sectionProps) return new SetPropertiesCommand(target.sheet, sectionProps, undefined, [target]);
	const cellProps = getCellProperties(range, target);
	return new SetPropertiesCommand(target.sheet, cellProps, undefined, [target]);
};

module.exports = {
	createPasteFormatsCommand
};
