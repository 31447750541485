const CellRange = require('../../model/CellRange');
const { getCellReference } = require('./cellrange');

const areEqual = (descr1, descr2) =>
	descr1.expr === descr2.expr && descr1.value === descr2.value && descr1.formula === descr2.formula;

const cellDescriptor = (reference, cell) => {
	const expr = cell && cell.getExpression();
	const value = cell ? cell.getValue() : undefined;
	const formula = expr ? expr.getFormula() : undefined;
	return { reference, formula, value, type: typeof value }; // level
};

// TODO: when relative, when absolute??
const iterateCellRange = (range, callback) => {
	const sheet = range.sheet;
	const tmprange = new CellRange(sheet, 0, 0, 0, 0);
	const dataProvider = range.data || sheet.cells;
	const startPos = range.data ? { x: range.getX1(), y: range.getY1() } : { x: 0, y: 0 };
	range.enumerateCells(false, (pos /* , cell */) => {
		const cell = dataProvider.getRC(pos.x - startPos.x, pos.y - startPos.y); // this.getRC(pos.x, pos.y);
		tmprange.set(pos.x, pos.y);
		callback(cell, getCellReference(tmprange));
	});
};

module.exports = {
	areEqual,
	cellDescriptor,
	iterateCellRange
};
