
const getSheetFromItem = (item) => {
	let sheet;
	if (item != null) sheet = item.isStreamSheet ? item : getSheetFromItem(item.getParent());
	return sheet;
};

const getStreamSheetId = (sheet) => {
	if (sheet) {
		const sheetContainer = sheet.isStreamSheet ? sheet.getStreamSheetContainer() : undefined;
		return sheetContainer ? sheetContainer.properties.sheetId : sheet.id;
	}
	return undefined;
};

module.exports = {
	getSheetFromItem,
	getStreamSheetId
};
