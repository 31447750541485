import React, { useContext, useEffect, useState } from 'react';
import { DEFAULT_LOCALE } from './translations';

let currentLocale = DEFAULT_LOCALE;

export const LocaleContext = React.createContext(currentLocale);

export const useLocale = () => {
	return useContext(LocaleContext);
};
export const getLocale = () => currentLocale;

export const LocaleProvider = (props) => {
	const [locale, setLocale] = useState(DEFAULT_LOCALE);
	useEffect(() => {
		currentLocale = locale;
	}, [locale]);
	return <LocaleContext.Provider value={{ locale, setLocale }}>{props.children}</LocaleContext.Provider>;
};
